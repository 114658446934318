<div class="d-flex">
  <ng-container *ngIf="booking.bookingRevenue?.products?.edges?.length">
    <ng-container
      *ngFor="let product of booking.bookingRevenue?.products?.edges | extractNodes; let first = first"
    >
      <span *ngIf="!first" class="v2-mx-2">&middot;</span>
      <typography-v2
        container="body"
        tooltip="Option"
        placement="top"
        [attr.dataId]="'option' + product.id"
        class="v2-mb-3"
        >{{ product.name }}</typography-v2
      >
    </ng-container>
  </ng-container>

  <ng-container *featureToggleOff="RdrFeature.pnr_page">
    <ng-container *ngIf="booking.bookingRevenue?.pnr">
      <span *ngIf="booking.bookingRevenue?.products?.edges?.length" class="v2-mx-2">&middot;</span>
      <typography-v2 container="body" tooltip="PNR" placement="top" dataId="pnr" class="v2-mb-3">
        {{ booking.bookingRevenue?.pnr }}</typography-v2
      >
    </ng-container>

    <ng-container *ngIf="booking.bookingRevenue?.pnr2">
      <span
        *ngIf="booking.bookingRevenue?.products?.edges?.length || booking.bookingRevenue?.pnr"
        class="v2-mx-2 v2-mb-3"
        >&middot;</span
      >
      <typography-v2 container="body" tooltip="PNR2" placement="top" dataId="pnr2"
        >{{ booking.bookingRevenue?.pnr2 }}</typography-v2
      >
    </ng-container>
  </ng-container>

  <ng-container *showForRole="UserType.TicketingAgent">
    <ng-container *ngIf="booking.bookingRevenue?.revenueSupplier?.name">
      <span class="v2-mx-2">&middot;</span>
      <typography-v2
        container="body"
        tooltip="Supplier"
        placement="top"
        dataId="supplier"
        class="v2-mb-3"
        >{{ booking.bookingRevenue?.revenueSupplier?.name }}</typography-v2
      >
    </ng-container>
  </ng-container>
</div>
